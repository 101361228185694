import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Часті запитання в тенісному клубі Tuliner
			</title>
			<meta name={"description"} content={"Ми розуміємо, що у вас можуть виникнути запитання про наш клуб, послуги та інше. Ось добірка найпоширеніших запитань, щоб допомогти вам краще."} />
			<meta property={"og:title"} content={"FAQ | Часті запитання в тенісному клубі Tuliner"} />
			<meta property={"og:description"} content={"Ми розуміємо, що у вас можуть виникнути запитання про наш клуб, послуги та інше. Ось добірка найпоширеніших запитань, щоб допомогти вам краще."} />
			<meta property={"og:image"} content={"https://castrestuliner.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://castrestuliner.com/img/3210120.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://castrestuliner.com/img/3210120.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://castrestuliner.com/img/3210120.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://castrestuliner.com/img/3210120.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://castrestuliner.com/img/3210120.png"} />
			<meta name={"msapplication-TileImage"} content={"https://castrestuliner.com/img/3210120.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-9">
			<Text margin="0px 0px 70px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1" sm-margin="0px 0px 50px 0px">
				Часті запитання (FAQ)
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи потрібно бути досвідченим гравцем, щоб приєднатися?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Зовсім ні! Tuliner вітає гравців усіх рівнів майстерності, від початківців до досвідчених гравців. Наші тренувальні програми розраховані на різні здібності.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Які вікові групи ви обслуговуєте?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Наші програми різноманітні, пропонуючи заняття для дітей, підлітків та дорослих. Конкретні вікові особливості можуть бути надані за запитом.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи можна взяти спорядження напрокат у клубі?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Так, ми пропонуємо широкий вибір високоякісного тенісного спорядження напрокат. Відвідайте наш професійний магазин для отримання додаткової інформації та індивідуальної консультації.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи доступні приватні коучингові сесії?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Безумовно. Ми пропонуємо індивідуальні коучингові сесії. Зв'яжіться з нами, щоб запланувати сесію з одним із наших професійних коучів.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Як забронювати матч?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Бронювання кортів можна здійснити через нашу рецепцію. Члени клубу мають пріоритетне право на бронювання, але не члени клубу також можуть орендувати корти за наявності вільних місць.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи проводите ви тенісні турніри?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Так, ми організовуємо регулярні місцеві та регіональні турніри для різних рівнів майстерності. Слідкуйте за нашим календарем подій, щоб дізнатися про майбутні турніри.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--dark" font="--headline2" md-margin="0px 0px 30px 0px">
					Додаткова допомога
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="--dark" font="--base">
					Якщо ваша цікавість не повністю задоволена або у вас залишилися конкретні запитання, будь ласка, не соромтеся звертатися до нас. Наша команда тенісного клубу Tuliner завжди готова допомогти вам з будь-якою додатковою інформацією, яка вам може знадобитися. Незалежно від того, чи це стосується наших послуг, деталей членства або будь-чого, пов'язаного з тенісом, ми тут для того, щоб ваш досвід роботи з нами був максимально гладким і приємним. Зверніться до нас і дозвольте нам допомогти вам зробити вашу тенісну подорож впевненою та легкою.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});